import Strapi from './Strapi';
import qs from 'qs';
const url = process.env.REACT_APP_API_URL;

const collection = 'forms';

const getForms = (query) => Strapi.get(`${collection}?${qs.stringify(query)}`);
const getFormCount = (query) => Strapi.get(`${collection}/count?${qs.stringify(query)}`);
const getFormById = (id) => Strapi.get(`${collection}/${id}`);
const getFormsTemplate = (query) => Strapi.get(`${collection}?template_eq=true&${qs.stringify(query)}`);
const getFormsExistingYears = (query) => Strapi.get(`${collection}/years?${qs.stringify(query)}`);
const getFormsByCompanyId = (id, query) => Strapi.get(`${collection}?companies=${id}&${qs.stringify(query)}`);
const getFormsErrors = (id) => Strapi.get(`${collection}/${id}/errors`);
const createForm = (body) => Strapi.post(`${collection}`, JSON.stringify(body));
const editForm = (id, body) => Strapi.put(`${collection}/${id}`, JSON.stringify(body));
const editFormStatus = (id, status) => Strapi.put(`${collection}/${id}/status/${status}`);
const updateFormConstant = (id,) => Strapi.put(`${collection}/${id}/constant`);
const delForm = (id) => Strapi.delete(`${collection}/${id}`);
const exportFormURL = (id, type) => `${url}/${collection}/${id}/export?type=${type}`;
const importFormTemplateUrl = (id) => `${url}/${collection}/${id}/import/template`;
const importForm = (id, file) => Strapi.post(`${collection}/${id}/import`, file);
const getCompletionRate = (query) => Strapi.get(`${collection}/completion?${qs.stringify(query)}`);

export {
  getForms,
  getFormCount,
  getFormById,
  getFormsTemplate,
  getFormsExistingYears,
  getFormsByCompanyId,
  getFormsErrors,
  createForm,
  delForm,
  editForm,
  editFormStatus,
  updateFormConstant,
  exportFormURL,
  importFormTemplateUrl,
  importForm,
  getCompletionRate,
};
